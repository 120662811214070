<template>
    <form @submit.prevent="submit">
      <loading-overlay
        :active="Loading"
        :is-full-page="true"
        loader="bars"
      />
      <CModalExtended
        :title="title"
        color="dark"
        size="lg"
        :show.sync="modalActive"
        :closeOnBackdrop="false"
        @update:show="$emit('close');"
      >
        <CRow>
            <CCol sm="12" lg="12">
              <CCardBody>
                <CRow>
                  <CCol sm="12" lg="12">
                    <CInput
                      :label="$t('label.name')+' '+'(ES)'"
                      addLabelClasses="required text-right"
                      v-uppercase
                      :horizontal="{label: 'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
                      :placeholder="$t('label.className')+' '+'(ES)'"
                      v-model="$v.formClass.TpCargoClassDsEs.$model"
                      :is-valid="hasError($v.formClass.TpCargoClassDsEs)"
                      :invalid-feedback="errorMessage($v.formClass.TpCargoClassDsEs)"
                    >
                    </CInput>    
                  </CCol>
                  <CCol sm="12" lg="12">
                    <CInput
                      :label="$t('label.name')+' '+'(EN)'"
                      addLabelClasses="required text-right"
                      v-uppercase
                      :horizontal="{label: 'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
                      :placeholder="$t('label.className')+' '+'(EN)'"
                      v-model="$v.formClass.TpCargoClassDsEn.$model"
                      :is-valid="hasError($v.formClass.TpCargoClassDsEn)"
                      :invalid-feedback="errorMessage($v.formClass.TpCargoClassDsEn)"
                    >
                    </CInput>    
                  </CCol>
                  <CCol sm="12" lg="12">
                    <CInput
                      :label="$t('label.code')"
                      addLabelClasses="required text-right"
                      v-uppercase
                      :horizontal="{label: 'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
                      :placeholder="$t('label.code')"
                      v-model="$v.formClass.TpCargoClassCode.$model"
                      :is-valid="hasError($v.formClass.TpCargoClassCode)"
                      :invalid-feedback="errorMessage($v.formClass.TpCargoClassCode)"
                    >
                    </CInput>    
                  </CCol>
                  <CCol sm="12" lg="12" v-if="edit">
                    <CSelect
                      :options="statusOptions"
                      :value.sync="formClass.Status"
                      :label="$t('label.status')"
                      :horizontal="{label: 'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
                      addLabelClasses="required text-right"
                      :is-valid="statusSelectColor"
                    />
                  </CCol>
                </CRow>
              </CCardBody>
            </CCol>
        </CRow>
        <template #footer>
            <CButton
            square
            color="add"
            class="d-flex align-items-center"
            :disabled="isSubmit"
            @click.stop="edit ? statusConfirmation(ClassItems.FgActTpCargoClass, formClass.Status, submit) : submit()"
            >
            <CIcon name="checkAlt"/>{{$t('button.accept')}}
            </CButton>
            <CButton
            square
            color="wipe"
            class="d-flex align-items-center"
            @click="$emit('close');"
            >
            <CIcon name="x" /><span class="ml-1">{{$t('button.cancel')}}</span>
            </CButton>
        </template>
      </CModalExtended>
    </form>
  </template>
  
  <script>
  import ModalMixin from '@/_mixins/modal';
  import UpperCase from '@/_validations/uppercase-directive';
  import { FormClass } from '@/_validations/contenedores/ContenedoresValidation';
  
  function data() {
  return {
    isSubmit: false,
    modalActive: false,
    Loading: false,
    formClass:{
     TpCargoClassId: '',
     TpCargoClassDsEs: '',
     TpCargoClassDsEn: '',
     TpCargoClassCode: '',
     Status: 0
    }
  };
  }
  
  function submit() {
  try {
    this.isSubmit = true;
    this.Loading = true;
    this.$v.$touch();
    if (this.$v.$error) {
    this.isSubmit = false;
    this.Loading = false;
    throw this.$t('label.errorsPleaseCheck');
    }
  
    let TpCargoClassJson = this.edit ? {
        TpCargoClassId: this.formClass.TpCargoClassId,
        TpCargoClassDsEs: this.formClass.TpCargoClassDsEs,
        TpCargoClassDsEn: this.formClass.TpCargoClassDsEn,
        TpCargoClassCode: this.formClass.TpCargoClassCode,
        Status: this.formClass.Status
    }:{
        TpCargoClassDsEs: this.formClass.TpCargoClassDsEs,
        TpCargoClassDsEn: this.formClass.TpCargoClassDsEn,
        TpCargoClassCode: this.formClass.TpCargoClassCode,
    };
  
    let metodo = this.edit ? 'PUT':'POST';
    let ruta = this.edit ? 'TpCargoClass-update': 'TpCargoClass-insert';
  
    this.$http.ejecutar(metodo, ruta, TpCargoClassJson, { root: 'TpCargoClassJson' })
    .then(response => {
        this.$emit('submited');
        this.modalActive = false;
        this.$notify({
        group: 'container',
        title: '¡Exito!',
        text: response.data.data[0].Response,
        type: "success"
        });
        this.resetForm();
    }).catch(err => {
        this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
        });
    }).then(() => {
        this.isSubmit = false;
        this.Loading = false;
    });
  } catch (error) {
    this.$notify({
    group: 'container',
    title: '¡Error!',
    text: error,
    type: "error"
    });
  }
  
  }
  
  function statusSelectColor() {
    return this.formClass.Status === 1;
  }
  
  function getData(val) {
    this.formClass.TpCargoClassId = val.TpCargoClassId;
    this.formClass.TpCargoClassDsEs = val.TpCargoClassDsEs;
    this.formClass.TpCargoClassDsEn = val.TpCargoClassDsEn;
    this.formClass.TpCargoClassCode = val.TpCargoClassCode;
    this.formClass.Status = val.FgActTpCargoClass ?1:0;
    this.$v.$touch();
  }
  
  function resetForm() {
    this.formClass.TpCargoClassId = '';
    this.formClass.TpCargoClassDsEs = '';
    this.formClass.TpCargoClassDsEn = '';
    this.formClass.TpCargoClassCode = '';
    this.formClass.Status = 0;
    this.$v.$reset();
  }
  
  
  export default {
  name: 'modal-classes',
  props: { modal: Boolean, edit: Boolean,title: String, ClassItems: Object},
  data,
  validations(){ return FormClass() },
  mixins: [
    ModalMixin,
  ],
  directives: UpperCase,
  methods: {
    statusSelectColor,
    getData,
    submit,
    resetForm,
  },
  watch: {
    modal: function (val) {
      this.modalActive = val;
      if(val){
        if (this.edit) {
          this.getData(this.ClassItems);
        }
      }else{
        this.resetForm();
      }
    },
  },
  
  };
  </script>
  